
.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;
}

.react-datepicker-wrapper {
    width: 100%;
    height: 100%
}

.date-picker {
    width: 100%;
}